$active_color: #B71D22;
.left_nav {
  float: left;
  width: 164px;
  background: #faf7f2;
  min-height: 675px;
  position: relative;
  .logo {
    margin: 48px auto 0;
    display: block;
  }
  .bottom_panel {
    //margin-top: 50px;
    //margin-bottom: 20px;
    text-align: center;
    width: 100%;
    bottom: 26px;
    position: absolute;
    .wechat_icon {
      font-size: 24px;
      color: #65523F;
      margin-bottom: 8px;
      display: inline-block;
      cursor: pointer;
      &:hover {
        color: #09bb07;
      }
    }
  }
  p {
    color: #8E8B87;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }
}