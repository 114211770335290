$active_color: #B71D22;
.left_menu {
  list-style: none;
  width: 93px;
  margin: 50px auto 0;
  padding: 0;
  .menu_nav_link {
    padding: 14px 0 7px 9px;
    border-bottom: 1px dashed #BFBFBF;
    position: relative;
    cursor: pointer;
    display: block;
    text-decoration: none;
    .label {
      font-size: 14px;
      color: #65523F;
    }
    .en_label {
      font-size: 12px;
      color: #8E8C87;
      margin-top: 3px;
      font-family: calibri;
      line-height: 12px;
    }
    .border_dot {
      width: 5px;
      height: 5px;
      background: #BFBFBF;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: -3px;
    }
    &.active {
      border-bottom: 1px solid $active_color;
      .label, .en_label {
        color: $active_color;
      }
      .border_dot {
        background: $active_color;
      }
    }
  }
}