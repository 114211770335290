.screen {
  //max-width: 1200px;
  //width: 100%;
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  .app {
    background: #faf7f2;
    box-shadow: 1px 1px 10px 4px #ccc;
    overflow: hidden;
    position: relative;
    &:after {
      display: block;
      clear: both;
      content: '';
      visibility: hidden;
    }
    .content {
      width: 1036px;
      min-height: 675px;
      float: right;
      background: #f3ece2;
      //height: 675px;
      //overflow-y: auto;
    }
  }
  .page_title {
    font-size: 31px;
    color: #65523F;
    font-weight: bold;
    line-height: 31px;
    //font-family: '宋体';
  }
  .page_sub_title {
    color: #C99D64;
    font-size: 19px;
  }
}

.page_loading {
  width: 100%;
  text-align: center;
  margin-top: 300px;
}